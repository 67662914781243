import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "100px 40px 100px 40px",
	"sm-padding": "40px 20px 40px 20px",
	"quarkly-title": "Footer",
	"background": " linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%)"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"margin": "0px 0px 100px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"text-transform": "uppercase",
			"lg-color": "--light",
			"color": "--light",
			"children": "Potion Punch"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"display": "grid",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "32px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"font": "--base",
			"text-transform": "uppercase",
			"children": "links",
			"color": "--light"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"text-decoration-line": "initial",
			"lg-href": "/privacy",
			"children": "Policy",
			"href": "/privacy",
			"color": "--light"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"text-decoration-line": "initial",
			"lg-href": "/cookie",
			"children": "Cookie",
			"href": "/cookie",
			"color": "--light"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"lg-margin": "0px 0px 0px auto"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"font": "--base",
			"text-transform": "uppercase",
			"children": "Contact us",
			"color": "--light"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "mailto:contact@homegardenlulu.com",
			"font": "--base",
			"text-decoration-line": "initial",
			"lg-href": "509 Peel St, Tamworth NSW 2340, Australia",
			"color": "--light",
			"children": "Collier Rd, Morley WA 6062, Australia"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"text-decoration-line": "initial",
			"lg-href": "contact@realmhorizon.com",
			"color": "--light",
			"href": "contact@questwander.com",
			"children": "contact@questwander.com"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"text-decoration-line": "initial",
			"lg-href": "+61434865353",
			"color": "--light",
			"href": "+61893754200",
			"children": "+61893754200"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			max-width="none"
			width="100%"
			sm-align-items="flex-start"
		/>
		{"        "}
		<Box {...override("box")}>
			{"        "}
			<Text {...override("text")} />
			{"    "}
		</Box>
		{"    "}
		<Box {...override("box1")}>
			{"        "}
			<Box {...override("box2")}>
				{"            "}
				<Text {...override("text1")} />
				{"            "}
				<Link {...override("link")} />
				{"            "}
				<Link {...override("link1")} />
				{"        "}
			</Box>
			{"        "}
			<Box {...override("box3")}>
				{"            "}
				<Text {...override("text2")} />
				{"            "}
				<Link {...override("link2")} />
				<Link {...override("link3")} />
				{"            "}
				<Link {...override("link4")} />
				{"        "}
			</Box>
			{"                    "}
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;